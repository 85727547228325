/* Página general */
.casos-exito-page {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.95), rgba(121, 9, 9, 0.85));
  font-family: 'Roboto', sans-serif;
  color: #ffffff;
  padding: 0;
  margin: 0;
}

.casos-exito-section {
  padding: 4rem 2rem;
  text-align: center;
}

.casos-exito-section h2 {
  font-size: 3rem;
  margin-bottom: 3rem;
  color: #ffffff;
  animation: fadeIn 1s ease-in-out;
}

.casos-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Tarjetas de video */
.video-card {
  background: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  padding: 1rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.video-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.7);
}

/* Video */
.video-frame {
  width: 100%;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 1rem;
  object-fit: cover;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

/* Modal */
.video-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease;
}

.video-modal-content {
  background: #1e1e1e;
  border-radius: 12px;
  padding: 1rem;
  max-width: 90%;
  width: 600px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.8);
  position: relative;
}

.video-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #ffffff;
  font-size: 1.5rem;
  cursor: pointer;
}

.video-modal-close:hover {
  color: #e50914;
}

.modal-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Animaciones */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .casos-exito-section h2 {
    font-size: 2.5rem;
  }

  .video-frame {
    height: 180px;
  }

  .video-modal-content {
    width: 90%;
  }
}
