body{
  background-image: url("./vectortittle.png");


}
.productos-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  color: #fff;
  background-color: #0000007c;
  
}

.productos-title {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
}

.categoria-section {
  margin-bottom: 50px;
}

.categoria-title {
  font-size: 28px;
  color: #ffffff;
  margin-bottom: 15px;
  border-bottom: 2px solid #ffcc00;
}

.productos-slider {
  padding: 10px 0;
}

.producto-slide {
  display: flex;
  justify-content: center;
}

.producto-card {
  background: #1b1b1b;
  border: 1px solid #444;
  border-radius: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 320px;
  text-align: center;
  padding: 15px;
  transition: transform 0.3s ease;
}

.producto-card:hover {
  transform: translateY(-5px);
}

.producto-imagen {
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 10px;
}

.producto-nombre {
  font-size: 18px;
  margin: 5px 0;
  color: #fff;
}

.producto-descripcion {
  font-size: 14px;
  color: #ccc;
}

.producto-precio {
  font-size: 20px;
  color: #ffcc00;
  margin: 5px 0;
}

.producto-boton {
  background: #000000;
  color: #ffffff;
  padding: 10px 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.producto-boton:hover {
  background: #ffaa00;
}
