.whatsapp-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    padding: 12px 30px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    background: linear-gradient(135deg, black, blue);
    border: none;
    border-radius: 50px; /* 🔥 Esquinas más suaves */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    position: relative;
    box-shadow: 0px 4px 15px rgba(0, 0, 255, 0.4); /* 🔥 Sombra ligera */
    overflow: hidden;
}

/* 🔥 Efecto al pasar el mouse */
.whatsapp-button:hover {
    transform: translateY(-3px); /* 🔥 Efecto de elevación */
    box-shadow: 0px 8px 20px rgba(0, 0, 255, 0.5); /* 🔥 Sombra más fuerte */
    background: linear-gradient(135deg, blue, black);
}

/* 🔥 Efecto sutil de luz en el borde */
.whatsapp-button::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 50px;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.whatsapp-button:hover::before {
    opacity: 1;
}

/* 🌍 RESPONSIVE */
@media (max-width: 768px) {
    .whatsapp-button {
        font-size: 14px;
        padding: 10px 25px;
        max-width: 220px;
    }
}

@media (max-width: 480px) {
    .whatsapp-button {
        font-size: 12px;
        padding: 8px 20px;
        max-width: 200px;
    }
}
