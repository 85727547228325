@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import url('https://fonts.google.com/specimen/Rubik+Glitch?query=glitch');
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

/* Estilos generales */
body {
  margin: 0;
  padding: 0;
  font-family: "Rubik-Glitch";
  background: linear-gradient(93deg, rgba(179,179,179,1) 7%, rgba(209,209,209,1) 21%, rgba(218,218,218,1) 45%, rgba(203,203,203,1) 56%, rgba(194,194,194,1) 84%, rgba(182,182,182,0.9664799025078782) 100%);
  line-height: 1.6;
  
}

h1, h2, h3 {
  margin: 0;  /*ajuste para todas las letras*/
}

/* Contenedor principal */
.home-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0%;
  
}

/*seccion caracteristicas web y botton el titulo ys ub están en componentes "tittle-section.jsx" */
.promo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;
  background:#0000000a;
  color: rgb(255, 255, 255);
  text-align: center;
  padding: 5px 20px;
  position: relative;
  overflow: hidden;
  
  
}
.promo-content {
  max-width: 600px;
  margin-top: 0%;
  animation: fadeInUp 1s ease-in-out;
  position: relative;
}


.btn-primary {
  display: inline-block;
  padding: 14px 30px;
  font-size: 1.2rem;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: white;
  background: linear-gradient(90deg, #585858, #000000f8);
  border-radius: 30px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(190, 190, 190, 0.434);
  margin-top: 25%;
}

.btn-primary:hover {
  background: linear-gradient(90deg, #000000c9, #373737e6);
  transform: scale(1.1);
  box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.96);
}

/*Zona caracteristicas featured*/
.features-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 105px 180px;
  
  border-radius: 7%;
  margin-top: -4%;
}

.feature-card {
  width: 280px;
  height: 380px;
  padding: 40px;
  background: rgba(0, 0, 0, 0.39);
  border-radius: 15px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0px 4px 10px rgb(255, 255, 255);
  
}

.feature-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 6px 15px rgb(0, 0, 0);
}

.feature-card h2 {
  font-size: 1.8rem;
  background: linear-gradient(45deg, #1b1b1b, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family:'Times New Roman', Times, serif
}

.feature-card h3 {
  font-size: 1.2rem;
  margin-top: 10px;
  opacity: 0.8;
  font-family: 'Courier New', Courier, monospace;
}

.feature-card p {
  font-size: 1.5rem;
  margin-top: 10px;
  font-family: 'Times New Roman', Times, serif;
  color: #000;
}


/* ARMADO DE SETUP SECTION */

.pc-setup-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.533);
  padding: 4rem 2rem;
  border-radius: 12px;
  overflow: hidden;
}

.pc-setup-container {
  display: flex;
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  align-items: center;
  padding-block: -5%;
}

.swiper-wrapper {
  flex: 1;
  min-width: 0; /* Evita que el swiper desborde */
  
}

.images-slider {
  width: 150%;
  max-height: 450px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 12px rgb(0, 0, 0);
  margin-left: -45%;
  
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}

.image-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  
}

.image-slide:hover {
  transform: scale(1.03);
}

.text-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  opacity: 0;
  animation: fadeInContent 1.2s ease forwards;
  animation-delay: 0.5s;
  
}

.text-wrapper h2 {
  font-size: 3rem;
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0;
  font-family:'Courier New', Courier, monospace;

}

.text-wrapper p {
  color: #ddd;
  font-size: 35px;
  font-family: 'Courier New', Courier, monospace;
  

}

.text-wrapper h3 {
  font-size: 20px;
  font-weight: normal;
  color: #ffcc00;
  margin-top: -2%;
}
/* solicitar catalogo button*/
.intro-button {
  background: #000000;
  color: #ffffff;
  border: none;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background 0.3s;
}

.intro-button:hover {
  background: #001797;
}

/* Fade in animation */
@keyframes fadeInContent {
  from {
      opacity: 0;
      transform: translateY(30px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.fade-in {
  opacity: 0;
  animation: fadeInContent 0.8s ease forwards;
}

.fade-in.delay-1 { animation-delay: 0.6s; }
.fade-in.delay-2 { animation-delay: 0.8s; }
.fade-in.delay-3 { animation-delay: 1s; }
.fade-in.delay-4 { animation-delay: 1.2s; }
.fade-in.delay-5 { animation-delay: 1.4s; }
.fade-in.delay-6 { animation-delay: 1.6s; }

/* Responsive */
@media (max-width: 768px) {
  .pc-setup-container {
      flex-direction: column;
      text-align: center;
  }

  .text-wrapper {
      align-items: center;
  }
}

/*Sección armado pc terminada*/


/*LAPTOPS PROMO SECTION*/

.laptops-section {
  background:#00000068;
  color: white;
  padding: 80px 5%;
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow: hidden;
}

.laptops-container {
  display: flex;
  max-width: 1200px;
  align-items: center;
  gap: 50px;
  animation: fadeInUp 1s ease-in-out;
  flex-direction: row;
  justify-content: space-between; 
}

/* --- CONTENIDO IZQUIERDO --- */
.laptops-content {
  flex: 1;
  text-align: left;
}

.laptops-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: monospace;
  color: #000000;
}

.laptops-description {
  font-size: 35px;
  opacity: 0.9;
  line-height: 1.5;
  margin-bottom: 20px;
  font-family: 'Courier New', Courier, monospace;

}

.laptops-benefits {
  list-style: none;
  padding: 0;
  color: #ffe206;

  
}

.laptops-benefits li {
  font-size: 1.5rem;
  margin: 8px 0;
  opacity: 0.9;
}

/* --- BOTÓN CTA --- */
.laptops-button {
  display: inline-block;
  padding: 12px 25px;
  background: #000000;
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  border-radius: 5px;
  transition: transform 0.3s ease-in-out, background 0.3s;
  margin-top: 20px;
}

.laptops-button:hover {
  background: #00104b;
  transform: scale(1.05);
}

/* --- IMAGEN --- */
.laptops-image {
  flex: 1;
  display: flex;
  justify-content: right;
  width: 100%;
  max-width: 600px;
  
}

.laptops-image img {
  width: 500%;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgb(255, 255, 255);
  animation: fadeInRight 1s ease-in-out;
  
}
@media (max-width: 768px) {
  .laptops-container {
    flex-direction: column; /* 🔥 En móviles, coloca la imagen debajo del texto */
    text-align: center;
  }

  .laptops-image {
    justify-content: center; /* 🔥 Centrar la imagen en pantallas pequeñas */
  }

  .laptops-image img {
    max-width: 90%; /* 🔥 Hace que la imagen ocupe mejor el espacio */
  }
}
/* --- ANIMACIONES --- */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@media (max-width: 768px) {
  .laptops-container {
    display: flex;
    flex-direction: column-reverse; /* 🔥 Invierte el orden: imagen arriba, texto abajo */
    text-align: center;
  }

  .laptops-image {
    order: -1; /* 🔥 Asegura que la imagen se coloque primero */
    margin-bottom: 20px;
  }

  .laptops-image img {
    max-width: 90%; /* 🔥 Ajusta el tamaño en móviles */
  }

  .laptops-content {
    text-align: center;
  }
}

@media (max-width: 480px) {
  .laptops-image img {
    max-width: 100%; /* 🔥 En móviles pequeños, la imagen ocupa todo el ancho */
  }

  .laptops-content {
    padding: 10px; /* 🔥 Reduce el espacio para mejor ajuste */
  }
}

/*RESPONSIVE DE LA SECCION LAPTOP*/
/* --- RESPONSIVE DESIGN --- */
@media (max-width: 1024px) {
  .laptops-container {
    flex-direction: column;
    text-align: center;
  }

  .laptops-content {
    text-align: center;
  }

  .laptops-image img {
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .laptops-title {
    font-size: 2rem;
  }

  .laptops-description {
    font-size: 1rem;
  }

  .laptops-benefits li {
    font-size: 1rem;
  }

  .laptops-button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .laptops-title {
    font-size: 1.8rem;
  }

  .laptops-description {
    font-size: 0.9rem;
  }

  .laptops-button {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}




/* SECCIONES  */
/* estilo general */
.info-section {
  background-color: #0000004d;
  color: #fff;
  padding: 60px 20px;
  text-align: center;
}

.info-section h2 {
  color: #ffffff;
  font-size: 2.5rem;
  margin-bottom: 50px;
  text-transform: uppercase;
  font-weight: bold;
  font-family:'Times New Roman', Times, serif;
}

/* Estilo de la cuadrícula */
.info-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  justify-items: center;
}

.info-card {
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 200px;
  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.info-card img {
  width: 65%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.info-card:hover img {
  transform: scale(1.1);
}

/* Adaptación para pantallas pequeñas de la seccion marcas */
@media (max-width: 768px) {
  .info-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .info-grid {
    grid-template-columns: 1fr;
  }
}



/* Estilo general para la sección de MARCAS */
.brands-section {
  padding: 4rem 2rem;
  text-align: center;
  background: #00000034; /* Fondo oscuro */
  color: #fff; /* Texto blanco */
  border-radius: 12px;
}

.brands-section h2 {
  font-size: 45px;
  margin-bottom: 2rem;
  color: #ffffff; /* Título rojo */
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Times New Roman', Times, serif;
}

/* Contenedor del carrusel */
.brands-carousel {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columnas por fila */
  gap: 2rem;
  justify-items: center;
  margin-top: 2rem;
  padding: 0 1rem;
}

/* Elementos individuales en el carrusel */
.brand-item {
  width: 295px;
  height: 210px;
  background-repeat: no-repeat; /*no repeditmos la imagen del brand*/
  background-size: contain; /*ajustamos la imagen para que todo calze*/
  background-position: center;
  border-radius: 10%;
  box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease, filter 0.3s ease;
  cursor: pointer;
  display: block; /* Aseguramos que sea un botón funcional */
}

.brand-item:hover {
  transform: scale(1.1) translateY(-5px);
  box-shadow: 0px 6px 15px rgba(255, 255, 255, 0.881);
  filter: brightness(1.2);
  background-color: #ffffff;
}


/* Estilo de cada imagen específica */
.brand-item-1 {
  background-image: url('/src/pages/marca1.png');
}

.brand-item-2 {
  background-image: url('/src/pages/marca2.png');
}

.brand-item-3 {
  background-image: url('/src/pages/marca3.png');
}

.brand-item-4 {
  background-image: url('/src/pages/marca4.png');
}

.brand-item-5 {
  background-image: url('/src/pages/kingston.png');
}

.brand-item-6 {
  background-image: url('/src/pages/marca6.png');
}

.brand-item-7 {
  background-image: url('/src/pages/marca7.png');
}

.brand-item-8 {
  background-image: url('/src/pages/marca8.png');
  
}

/* 🌍 RESPONSIVE DESIGN */

/* 📱 Móviles y tablets pequeñas */
@media (max-width: 1024px) {
  .home-container {
    gap: 2rem;
    padding: 1rem 1rem;
    
  }

  .promo-section {
    height: auto;
    padding: 40px 20px;
  }

  .promo-content {
    max-width: 90%;
  }

  .btn-primary {
    font-size: 1rem;
    padding: 12px 25px;
    margin-top: 10%;
  }

  .features-section {
    flex-direction: column;
    padding: 50px 20px;
    gap: 15px;
  }

  .feature-card {
    width: 90%;
    max-width: 320px;
    height: auto;
    padding: 20px;
  }

  .pc-setup-section {
    flex-direction: column;
    padding: 3rem 1rem;
  }

  .pc-setup-container {
    flex-direction: column;
    gap: 1.5rem;
  }

  .images-slider {
    width: 100%;
    max-width: 450px;
    margin-left: 0;
  }

  .text-wrapper {
    text-align: center;
    align-items: center;

  }

  .text-wrapper h2 {
    font-size: 2rem;
  }

  .text-wrapper p {
    font-size: 1rem;
  }

  .info-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }

  .brands-carousel {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  .brand-item {
    width: 90%;
    max-width: 180px;
    height: 140px;
  }
}

/* 📱 Dispositivos menores a 768px (móviles medianos y pequeños) */
@media (max-width: 768px) {
  .home-container {
    gap: 1.5rem;
    padding: 1.5rem;
  }

  .promo-section {
    padding: 30px 15px;
  }

  .promo-content {
    max-width: 100%;
  }

  .btn-primary {
    font-size: 1rem;
    padding: 10px 20px;
  }

  .features-section {
    padding: 40px 15px;
  }

  .feature-card {
    width: 95%;
    max-width: 300px;
  }

  .pc-setup-section {
    padding: 2rem 1rem;
  }

  .text-wrapper h2 {
    font-size: 1.8rem;
  }

  .text-wrapper p {
    font-size: 0.9rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .brands-carousel {
    grid-template-columns: 1fr;
  }

  .brand-item {
    width: 85%;
    max-width: 160px;
    height: 120px;
  }
}

/* 📱 Dispositivos menores a 480px (móviles pequeños) */
@media (max-width: 480px) {
  .home-container {
    gap: 1rem;
    padding: 1rem;
  }

  .promo-section {
    padding: 25px 10px;
  }

  .promo-content {
    max-width: 100%;
  }

  .btn-primary {
    font-size: 0.9rem;
    padding: 8px 18px;
  }

  .features-section {
    padding: 30px 10px;
  }

  .feature-card {
    width: 100%;
    max-width: 280px;
  }

  .pc-setup-section {
    padding: 1.5rem 0.5rem;
  }

  .text-wrapper h2 {
    font-size: 1.5rem;
  }

  .text-wrapper p {
    font-size: 0.8rem;
  }

  .info-grid {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .brands-carousel {
    grid-template-columns: 1fr;
  }

  .brand-item {
    width: 100%;
    max-width: 140px;
    height: 100px;
  }
}





