@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");

.footer {
    width: 100%;
    background: #000000;
    color: white;
    padding: 2rem 3%;
    font-family: "Poppins", sans-serif;
    max-width: 100vw; /* 🔥 Evita que sobrepase la pantalla */
    overflow-x: hidden; /* 🔥 Elimina el desplazamiento horizontal */
    position: relative; /* 🔥 Mantiene el footer fijo en su lugar */
}

/* 🔹 Contenedor General - Usa Flexbox para organizarlo bien */
.footer-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px; /* 🔥 Evita que se estire demasiado */
    width: 100%;
    margin: 0 auto; /* 🔥 Centra todo el contenido del footer */
    padding: 0 20px; /* 🔥 Asegura márgenes internos sin desbordamiento */
    box-sizing: border-box; /* 🔥 Evita que el padding cause problemas de tamaño */
}

@media (max-width: 1024px) {
    .footer-container {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
    }
}
/* 🔹 Sección Izquierda (LOGO + BOTONES) */
.footer-left {
    display: flex;
    align-items: center;
    gap: 2rem;
    flex: 1;
    justify-content: flex-start; /* 🔥 Pegado completamente a la izquierda */
}

/* 🔹 Logo */
.footer-logo img {
    width: 90px;
    transition: transform 0.3s ease;
}

.footer-logo img:hover {
    transform: scale(1.05);
}

/* 🔹 Botones de Navegación */
.footer-nav {
    display: flex;
    gap: 1rem;
}

.footer-button {
    background: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 20px;
    padding: 0.6rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.footer-button:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
}

/* 🔹 FRASE CENTRADA */
.footer-phrase {
    flex: 2; /* 🔥 Permite que el texto se mantenga en el centro */
    font-size: 1.4rem;
    font-family: "Orbitron", sans-serif;
    font-weight: 600;
    letter-spacing: 1.2px;
    text-align: center;
    background: linear-gradient(90deg, #ff0040, #ff7300);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* 🔹 Redes Sociales a la Derecha */
.footer-socials {
    display: flex;
    gap: 1.2rem;
    flex: 1;
    justify-content: flex-end; /* 🔥 Pegado completamente a la derecha */
}

.social-icon {
    font-size: 1.5rem;
    color: rgba(255, 255, 255, 0.8);
    transition: color 0.3s ease, transform 0.3s ease;
}

.social-icon:hover {
    color: #ff0040;
    transform: scale(1.3);
}

/* 🔹 Derechos Reservados */
.footer-copy {
    font-size: 0.9rem;
    text-align: center;
    margin-top: 1.5rem;
    color: rgba(255, 255, 255, 0.7);
}

/* 🌍 RESPONSIVE */

/* 📏 Para Tablets y Pantallas Medianas */
@media (max-width: 1024px) {
    .footer-container {
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        gap: 1.5rem;
    }

    .footer-left {
        flex-direction: column;
        align-items: center;
    }

    .footer-phrase {
        font-size: 1.2rem;
    }

    .footer-socials {
        justify-content: center;
    }
}

/* 📏 Para Dispositivos Menores a 768px (Móviles) */
@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        text-align: center;
        gap: 1.5rem;
    }

    .footer-left {
        flex-direction: column;
        align-items: center;
        gap: 1.2rem;
    }

    .footer-logo img {
        width: 75px;
    }

    .footer-nav {
        flex-direction: column;
        align-items: center;
        gap: 0.6rem;
    }

    .footer-phrase {
        font-size: 1.1rem;
    }

    .footer-socials {
        justify-content: center;
        margin-top: 0.8rem;
    }
}

/* 📏 Para Dispositivos Menores a 480px (Móviles Pequeños) */
@media (max-width: 480px) {
    .footer-container {
        padding: 1.5rem;
    }

    .footer-left {
        flex-direction: column;
        text-align: center;
        gap: 1rem;
    }

    .footer-logo img {
        width: 65px;
    }

    .footer-nav {
        gap: 0.4rem;
    }

    .footer-phrase {
        font-size: 1rem;
    }

    .footer-socials {
        gap: 1rem;
    }

    .footer-copy {
        font-size: 0.8rem;
    }
}
