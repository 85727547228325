/* 🔥 VARIABLES CSS */
:root {
  --color-black: #000000;/*color tittle*/
  --color-white: #ffffff3d; /*colo para <p>*/
  --color-gray: #383838;
  
  --color-divider: #666666;
  --max-width: 1200px;
  --padding-section: 4rem 2rem;
  --border-radius: 8px;
  --box-shadow: 0 4px 12px rgba(48, 48, 48, 0.879);
}

/* 🔥 ESTILOS GENERALES */
.cybersecurity-section {
  background: var(--color-white);
  background-image: url("./vectortittle.png");
  color: var(--color-black);
  font-family:'Courier New', Courier, monospace;
  line-height: 1.6;
}

.section-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--color-black);
  text-align: center;
  margin-bottom: 2rem;
}

/* 🔥 EFECTOS DE ENTRADA */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-out forwards;
}

.slide-in-left {
  opacity: 0;
  transform: translateX(-50px);
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-right {
  opacity: 0;
  transform: translateX(50px);
  animation: slideInRight 1s ease-out forwards;
}

.zoom-in {
  opacity: 0;
  transform: scale(0.9);
  animation: zoomIn 1s ease-out forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@keyframes slideInLeft {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoomIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/*BOTON CONTRATAR AUDITORIA */

.audit-button-container {
  text-align: center;
  margin-top: 20px;
}

/* 🔥 BOTÓN ESTILIZADO 🔥 */
.audit-button {
  display: inline-block;
  padding: 14px 24px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(135deg, #000000, #292929);
  border-radius: 50px;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 4px 15px rgba(0, 37, 221, 0.823);
  position: relative;
  overflow: hidden;
}

/* ✨ EFECTO HOVER ✨ */
.audit-button:hover {
  transform: scale(1.1);
  background: linear-gradient(135deg, #000089, #0011ff);
  box-shadow: 0px 6px 20px rgb(0, 67, 190);
}

/* 🎨 EFECTO ANIMADO AL HACER CLICK */
.audit-button:active {
  transform: scale(0.95);
}

/* 🌍 RESPONSIVE */
@media (max-width: 768px) {
  .audit-button {
    font-size: 1rem;
    padding: 12px 20px;
  }
}

@media (max-width: 480px) {
  .audit-button {
    font-size: 0.9rem;
    padding: 10px 18px;
  }
}



/* 🔥 SECCIÓN DE SERVICIOS Y ATAQUES */
.services-section, .attacks-section, .news-section {
  max-width: var(--max-width);
  margin: 0 auto;
  padding: var(--padding-section);
  
}

.service-item, .attack-item, .news-item {
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 2rem;
}

.service-image, .attack-image {
  flex: 1;
}

.service-image img, .attack-image img {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
}

.service-content, .attack-content {
  flex: 2;
}

.service-item h2, .attack-item h2, .news-item h2 {
  font-size: 1.8rem;
  color: var(--color-black);
  margin-bottom: 1rem;
}

.service-item p, .attack-item p, .news-item p {
  font-size: 1.1rem;
  color: var(--color-gray);
}

/* 🔥 DIVISORES MODERNOS */
.divider {
  width: 100%;
  height: 1px;
  background: var(--color-divider);
  margin: 2rem 0;
}

/* 🔥 SECCIÓN DE NOTICIAS */
.news-item {
  padding: 1rem 0;
}

.news-item h2 {
  font-size: 1.5rem;
  color: var(--color-black);
}

.news-item p {
  font-size: 1rem;
  color: var(--color-gray);
}

/* 🌍 RESPONSIVE DESIGN */
@media (max-width: 768px) {
  .service-item, .attack-item {
    flex-direction: column;
    text-align: center;
  }

  .service-image, .attack-image {
    margin-bottom: 1rem;
  }

  .section-title {
    font-size: 2rem;
  }

  .service-item h2, .attack-item h2, .news-item h2 {
    font-size: 1.5rem;
  }

  .service-item p, .attack-item p, .news-item p {
    font-size: 1rem;
  }
}