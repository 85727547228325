/* Botón flotante del carrito */
.carrito-boton {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #ffffff; /* Rojo elegante */
    color: white;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
  }
  
  .carrito-boton:hover {
    transform: scale(1.1);
  }
  
  /* Carrito desplegable lateral */
  .carrito-lateral {
    position: fixed;
    top: 60px; /* Debajo del navbar */
    right: -300px; /* Oculto inicialmente */
    width: 300px; /* Más compacto */
    height: calc(70% - 60px);
    background: #000000b8; /* Fondo gris oscuro */
    box-shadow: -4px 0 10px rgba(0, 0, 0, 0.4);
    border-radius: 8px 0 0 8px;
    overflow: hidden;
    z-index: 1000;
    transition: right 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
  }
  
  .carrito-lateral.mostrar {
    right: 0;
  }
  
  /* Header del carrito */
  .carrito-header {
    padding: 10px 15px;
    background: #232323; /* Rojo elegante */
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .carrito-cerrar {
    background: none;
    border: none;
    color: rgb(255, 251, 251);
    font-size: 18px;
    cursor: pointer;
  }
  
  /* Contenido del carrito */
  .carrito-body {
    padding: 15px;
    overflow-y: auto;
    flex: 1;
    color: #ffffff; /* Texto blanco grisáceo */
  }
  
  .carrito-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #959595; /* Línea gris oscuro */
    padding-bottom: 10px;
  }
  
  .carrito-imagen {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .carrito-detalles {
    flex: 1;
  }
  
  .carrito-nombre {
    font-size: 20px;
    margin: 0 0 5px;
  }
  
  .carrito-precio {
    color: #4bb500; 
    font-weight: bold;
    font-size: 18px;
  }
  
  .carrito-controles {
    display: flex;
    align-items: center;
    gap: 22px;
  }
  
  .btn-menos,
  .btn-mas {
    background: #757575; /* Gris intermedio */
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    color: white;
  }
  
  .btn-menos:hover,
  .btn-mas:hover {
    background: #616161; /* Gris oscuro */
  }
  
  .btn-eliminar-x {
    background: #d3000007; /* Rojo intenso */
    border: 1px;
    border-color: #f5f5f5;
    border-radius: 4px;
    padding: 5px;
    cursor: pointer;
    color: white;
    font-size: 12px;
  }
  
  .btn-eliminar-x:hover {
    background: #0b5200bf;
  }
  .boton-armado {
    background: #ffc107;
    border: none;
    color: #000;
    padding: 8px 16px;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .boton-armado.activo {
    background: #28a745;
    color: white;
  }
  
  /* Footer del carrito */
  .carrito-footer {
    padding: 10px 15px;
    border-top: 1px solid #444;
    background: #1c1c1c; /* Fondo gris oscuro */
    text-align: center;
  }
  
  .carrito-total {
    font-size: 22px;
    font-weight: bold;
    color: #f5f5f5;
    margin-bottom: 10px;
  }
  
  .carrito-comprar {
    background: #ffffff; /* Rojo elegante */
    color: rgb(0, 0, 0);
    border: none;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 14px;
  
  }
  
  .carrito-comprar:hover {
    background: #21a300d3;
  }
  
  /* FORMULARIO EMERGENTE */
  
  .formulario-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .formulario-modal {
    background: #000000d2;
    padding: 40px;
    border-radius: 8px;
    width: 95%;
    max-width: 700px;
    text-align: center;
  }
  
  .formulario-modal h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
  }
  
  .formulario-modal input,
  .formulario-modal textarea {
    width: 100%;
    padding: 5px;
    margin-bottom: 10px;
    border: 1px solid #000000;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .btn-comprar {
    background: #006618;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .btn-cancelar {
    background: #000000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .btn-comprar:hover {
    background: #218838;
  }
  
  .btn-cancelar:hover {
    background: #c60101;
  }
  .mensaje-advertencia {
    position: fixed; /* Mantiene el mensaje en pantalla */
    top: 20px; /* Espaciado desde la parte superior */
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffe4e4;
    color: #d8000c;
    border: 2px solid #d8000c;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 99999; /* Asegura que esté por encima de otros elementos */
    max-width: 90%;
    width: 400px;
    animation: fadeIn 0.5s ease-in-out, bounce 1s infinite alternate;
  }
  
  .mensaje-advertencia ul {
    margin: 10px 0 0;
    padding: 0;
    list-style: none;
  }
  
  .mensaje-advertencia li {
    margin: 5px 0;
    font-size: 14px;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes bounce {
    from {
      transform: translateX(-50%) translateY(0);
    }
    to {
      transform: translateX(-50%) translateY(5px);
    }
  }
  
  
  
  /* --- Responsivo --- */
  /* Ajuste para tablets y pantallas medianas */
  @media (max-width: 768px) {
    .carrito-lateral {
      width: 250px;
      height: calc(100% - 70px); /* Altura más compacta */
    }
  
    .carrito-body {
      padding: 10px;
    }
  
    .carrito-item {
      margin-bottom: 10px;
    }
  
    .carrito-nombre {
      font-size: 12px;
    }
  
    .carrito-precio {
      font-size: 12px;
    }
  
    .btn-menos,
    .btn-mas {
      font-size: 12px;
      padding: 4px 8px;
    }
  
    .btn-eliminar-x {
      font-size: 10px;
      padding: 4px;
    }
  
    .carrito-total {
      font-size: 14px;
    }
  
    .carrito-comprar {
      font-size: 12px;
      padding: 6px 12px;
    }
  }
  
  /* Ajuste para móviles */
  @media (max-width: 480px) {
    .carrito-lateral {
      width: 200px;
      height: calc(100% - 70px);
    }
  
    .carrito-body {
      padding: 5px;
    }
  
    .carrito-item {
      margin-bottom: 8px;
    }
  
    .carrito-nombre,
    .carrito-precio {
      font-size: 10px;
    }
  
    .btn-menos,
    .btn-mas {
      font-size: 10px;
      padding: 3px 6px;
    }
  
    .btn-eliminar-x {
      font-size: 10px;
      padding: 3px;
    }
  
    .carrito-total {
      font-size: 12px;
    }
  
    .carrito-comprar {
      font-size: 10px;
      padding: 4px 8px;
    }
  }