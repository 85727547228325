/* Estilo general del navbar */
.navbar {
    display: flex;
    
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
    background: linear-gradient(135deg, #000000f7, #00000071);
    color: #fff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    
  }
  
  .navbar.scrolled {
    background: linear-gradient(135deg, #00000086, #000000e8);
    box-shadow: 0px 6px 15px rgb(0, 0, 0);
  }
  
/*VIDEO DE NAVBAR */

/* --- Contenedor del Video --- */
.navbar-video-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

/* --- Video de Fondo --- */
.navbar-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.3; /* Ajusta la opacidad para mayor visibilidad del texto */
}



  /* Logo */
  .navbar-brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .navbar-brand img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  
  .navbar-brand .logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
  }
  
 /* Links del navbar */
.navbar-links {
  display: flex;
  gap: 2rem;
}

.navbar-links a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  position: relative;
  overflow: hidden; /* Para que la animación no se desborde */
}

.navbar-links a::after {
  content: '';
  position: absolute;
  left: -100%; /* Empieza fuera de la vista */
  bottom: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, #ffffffc0, #ffffffbc, #000000bb);
  animation: light-sweep 6s infinite; /* Animación en bucle */
}

@keyframes light-sweep {
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

/* Efecto al pasar el mouse */
.navbar-links a:hover {
  color: linear-gradient(to right, #000000c0, #000000bc, #000000bb);
}

.navbar-links a:hover::after {
  animation: light-sweep-hover 0.7s infinite;
}

@keyframes light-sweep-hover {
  0% {
    left: -100%;
  }
  50% {
    left: 0;
  }
  100% {
    left: 100%;
  }
}

/* Botón de hamburguesa */
.navbar-toggle {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 0.3rem;
}

.navbar-toggle .bar {
  width: 25px;
  height: 3px;
  background: #ff0000df;
  border-radius: 5px;
  transition: all 0.3s ease;
}

/* Responsive */
@media (max-width: 368px) {
  .navbar-links {
    flex-direction: column;
    gap: 1rem;
    position: absolute;
    top: 100%;
    right: 0;
    background: #101010;
    width: 345px;
    padding: 0.5PX;
    transform: translateY(-200%);
    transition: transform 0.3s ease;
  }

  .navbar-links.open {
    transform: translateY(0%);
  }

  .navbar-toggle {
    display: flex;
  }
}

/* 📌 Ajuste para móviles: Navbar Responsive */
@media (max-width: 768px) {
  .navbar {
    padding: 1rem 1.5rem; /* 🔥 Reduce el espacio en móviles */
    flex-direction: row;
  }

  .navbar-links {
    display: none; /* 🔥 Oculta el menú en móviles */
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.95);
    padding: 1rem;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }

  /* ✅ Muestra el menú cuando esté abierto */
  .navbar-links.open {
    display: flex;
  }

  .navbar-links a {
    display: block;
    padding: 0.8rem;
    font-size: 1rem;
  }

  /* 📌 Ajuste del botón de hamburguesa */
  .navbar-toggle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 5px;
  }

  .navbar-toggle .bar {
    width: 30px;
    height: 3px;
    background: #ffffff;
    border-radius: 5px;
    transition: all 0.3s ease;
  }
}

/* 📌 Ajuste para iPhones y móviles pequeños */
@media (max-width: 400px) {
  .navbar-links {
    width: 100%;
    right: 0;
    left: 0;
  }
}