.build-pc-page {
    background: rgba(0, 0, 0, 0.697);
    background: linear-gradient(74deg, rgba(0,0,0,1) 11%, rgba(121,9,9,0.9766107126444328) 48%, rgba(0,0,0,1) 94%);  /* Fondo oscuro para un look tecnológico */
    font-family: 'Roboto', sans-serif;
    color: #ffffff;
    padding-left: 20%;
    padding-right: 20%;
  }
  
  .build-section {
    padding: 4rem 2rem;
    text-align: center;
  }
  
  .build-section h2 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #ffffff;
    font-family: Georgia, 'Times New Roman', Times, serif;
  }
  .build-section h3 {

  margin-top: -2%;
  margin-bottom: 2%;

  }
  
  .categories {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .category {
    background: #000000d9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.355);
    padding: 1rem;
    text-align: center;
  }
  
  .category h3 {
    margin-bottom: 1rem;
    font-size: 1.2rem;
    color: #ffffff;
  }
  
  .component-button {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0.5rem;
    background: #e9ecef;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .component-button:hover {
    background: #d1d5db;
  }
  
  .component-button.selected {
    background: #ef0000;
    color: #fff;
  }
  
  .total-section {
    text-align: center;
    padding-bottom: 5%;
    background: #000000b6;
    border-top: 2px solid #dfdfdf;
    
  }
  
  .total-section h2 {
    margin-bottom: 1rem;
    font-size: 2rem;
    color: #ffffff;
  }
  
  .total-section p {
    font-size: 45px;
    margin-bottom: 2rem;
    color: #ffffff;
    font-family: 'Times New Roman', Times, serif;
  }
  
  .action-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .buy-button {
    padding: 0.7rem 1.5rem;
    background: #ffffffe0;
    color: #000000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .buy-button:hover {
    background: #dfdfdfb2;
  }


  
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9); /* Fondo negro semi-transparente */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content */
.modal-content {
  background: rgba(0, 0, 0, 0.787); /* Fondo blanco */
  border: 3px solid #ffffffc9; /* Borde rojo */
  border-radius: 15px;
  padding: 2rem;
  text-align: center;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5); /* Sombra para profundidad */
}

/* Imagen dentro del modal */
.modal-content img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Bordes suaves */
  border: 2px solid #c80000; /* Borde rojo alrededor de la imagen */
}

/* Título del modal */
.modal-content h3 {
  margin-top: 1rem;
  font-size: 1.8rem;
  color: #ce0000; /* Texto rojo */
  font-weight: bold;
}

/* Descripción del modal */
.modal-content p {
  margin: 1rem 0;
  font-size: 1rem;
  color: rgb(255, 255, 255); /* Texto negro */
  line-height: 1.5;
}

/* Enlace dentro del modal */
.modal-content a {
  display: inline-block;
  margin-bottom: 1rem;
  color: #c80000; /* Rojo */
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s;
}

.modal-content a:hover {
  color: black; /* Cambia a negro al pasar el cursor */
}

.modal-content .select-button {
  background: #c80000!important;
  color: white!important;
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
}

.modal-content .close-button {
  background: black !important;
  color: white!important;
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.2s;
}




/* Contenedor de la vista previa */
.hover-preview {
  position: absolute; /* Posiciona la vista previa respecto al ítem */
  top: 50%; /* Centra verticalmente con respecto al ítem */
  left: 105%; /* Coloca la vista previa a la derecha */
  transform: translateY(-50%); /* Ajusta para centrar perfectamente */
  background: rgb(0, 0, 0);
  border: 2px solid #ffffff7a;
  border-radius: 10px;
  padding: 1rem;
  width: 300px;
  z-index: 1000;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  transition: opacity 0.3s ease, transform 0.2s ease;
}
.category ul li {
  position: relative; /* Contenedor relativo para posicionar la vista previa */
}

/* Animación de entrada/salida */
.hover-preview.hidden {
  opacity: 0;
  transform: translateX(-10px);
}

/* Contenido de la vista previa */
.preview-content img {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 1rem;
}

.preview-content h3 {
  font-size: 1.2rem;
  color: #c80000;
  margin-bottom: 0.5rem;
}

.preview-content p {
  font-size: 0.9rem;
  color: rgb(255, 255, 255);
  line-height: 1.4;
}



/* Evitar conflictos con estilos */
.form-modal-content input, 
.form-modal-content textarea {
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
