@import url("https://fonts.googleapis.com/css2?family=Avant+Garde:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&display=swap");
body{
  background-image: url("./vectortittle.png");


}


.title-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 30vh; /* 🔥 SECCIÓN MÁS BAJA */
  padding: 50px 5%;
  
  background-size: cover;
  text-align: center;
  border-radius: 2%;
  border-color: #000000;  
  
  
}

/* 🔹 Título con Sombra Elegante */
.text-effect {
  font-family: "Avant Garde", Avantgarde, "Century Gothic", CenturyGothic, "AppleGothic", sans-serif;
  font-size: 72px;
  padding: 40px 20px;
  text-transform: uppercase;
  text-rendering: optimizeLegibility;
  max-width: 90%;
  letter-spacing: 0.15em;
  margin: 0 auto;
  color: #131313; /* 🔥 TEXTO NEGRO */
  text-shadow:
    1px -1px 0 #767676,
    -1px 2px 1px #737272,
    -2px 4px 1px #767474,
    -3px 6px 1px #787777,
    -4px 8px 1px #7b7a7a,
    -5px 10px 1px #7f7d7d,
    -6px 12px 1px #828181,
    -7px 14px 1px #868585,
    -8px 16px 1px #8b8a89,
    -9px 18px 1px #8f8e8d,
    -10px 20px 1px #949392,
    -11px 22px 1px #999897,
    -12px 24px 1px #9e9c9c;
}

/* 🔹 Subtítulo Animado Centrado */
.changing-subtitle {
  font-size: 1.8rem;
  font-weight: 500;
  
  color: #131313; /* 🔥 TEXTO NEGRO */
  font-family: "Orbitron", sans-serif;
  margin-top: 10px;
  text-align: center;
  opacity: 0;
  animation: fadeInUp 1.5s ease-in-out forwards, textChange 4s infinite ease-in-out;
  text-shadow:   1px -1px 0 #767676,
  -1px 2px 1px #737272,
  -2px 4px 1px #767474,
  -3px 6px 1px #787777,
  -4px 8px 1px #7b7a7a,
  -5px 10px 1px #7f7d7d;
}

/* 🔹 Animaciones */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes textChange {
  0% { opacity: 0; transform: translateY(20px); }
  20% { opacity: 1; transform: translateY(0); }
  80% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}

/* 🌍 RESPONSIVE DESIGN */

/* 📏 Tablets */
@media (max-width: 1024px) {
  .title-section {
    padding: 40px 20px;
    min-height: 50vh; /* 🔥 REDUCE ALTURA EN TABLETS */
  }

  .text-effect {
    font-size: 58px;
    padding: 30px 15px;
  }

  .changing-subtitle {
    font-size: 1.6rem;
  }
}

/* 📏 Celulares (Menos de 768px) */
@media (max-width: 768px) {
  .title-section {
    padding: 30px 10px;
    min-height: 45vh; /* 🔥 AJUSTE PARA CELULARES */
  }

  .text-effect {
    font-size: 46px;
    padding: 20px 10px;
    letter-spacing: 0.1em;
  }

  .changing-subtitle {
    font-size: 1.4rem;
  }
}

/* 📏 Celulares Pequeños (Menos de 480px) */
@media (max-width: 480px) {
  .title-section {
    padding: 20px 5px;
    min-height: 40vh; /* 🔥 AJUSTE PARA CELULARES CHICOS */
  }

  .text-effect {
    font-size: 38px;
    padding: 15px 5px;
  }

  .changing-subtitle {
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .title-section {
    height: 55vh; /* 🔥 Subí la altura para que el fondo no se corte */
  }
}
/* PARA MOVILES COMO MI PIXEL*/
@media (max-width: 480px) {
  .title-section {
    height: 10vh; /* 🔥 Más alto en móviles pequeños */
    margin-top: -5%;
  }
}

