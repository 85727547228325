/* Importar tipografías modernas */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&family=Roboto:wght@300;400;500&display=swap');

body {
  font-family: 'Roboto', sans-serif;
  background-color: #121212;
  color: #ffffff;
  margin: 0;
  padding: 0;
}

/* Animación de entrada */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Página de servicios */
.servicios-page {
  padding: 4rem 2rem;
  text-align: center;
}

/* Título de la sección */
.servicios-section h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 3.5rem;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 3rem;
  color: #000000;
  text-shadow: 0 4px 10px rgba(255, 255, 255, 0.5);
  font-family: 'Courier New', Courier, monospace;
}

/* Diseño de servicios alternados */
.servicio {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 4rem;
  padding: 2rem;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.533);
  box-shadow: 0 10px 30px rgba(255, 255, 255, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  opacity: 0;
  animation: fadeInUp 0.8s ease-out forwards;
}

/* Efecto de entrada en orden */
.servicio:nth-child(1) { animation-delay: 0.2s; }
.servicio:nth-child(2) { animation-delay: 0.4s; }
.servicio:nth-child(3) { animation-delay: 0.6s; }
.servicio:nth-child(4) { animation-delay: 0.8s; }

.servicio:nth-child(even) {
  flex-direction: row-reverse;
}

.servicio:hover {
  transform: scale(1.03);
  box-shadow: 0 15px 40px rgba(255, 255, 255, 0.15);
}

/* Imagen del servicio */
.servicio img {
  width: 80%;
  max-width: 750px;
  border-radius: 12px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.servicio:hover img {
  transform: scale(1.05);
}

/* Información del servicio */
.servicio-info {
  width: 50%;
  text-align: left;
}

.servicio-info h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #ffffff;
  margin-bottom: 1rem;
}

.servicio-info p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.8;
  color: #e0e0e0;
}

/* Características destacadas */
.servicio-caracteristicas {
  font-size: 1.3rem;
  font-weight: bold;
  color: #ffcc00;
  margin-top: 10px;
}

/* Botones de contacto */
.contacto-buttons {
  margin-top: 1.5rem;
  display: flex;
  gap: 1rem;
}

.contacto-button {
  background: linear-gradient(135deg, #ffffff, #e0e0e0);
  color: #000000;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
}

.contacto-button:hover {
  background: linear-gradient(135deg, #ffcc00, #ffaa00);
  color: #000;
  transform: translateY(-3px) scale(1.05);
  box-shadow: 0 6px 15px rgba(255, 204, 0, 0.5);
}

.contacto-button:active {
  transform: scale(0.95);
}

/* Responsive */
@media (max-width: 768px) {
  .servicio {
    flex-direction: column;
    text-align: center;
  }

  .servicio:nth-child(even) {
    flex-direction: column;
  }

  .servicio-info {
    width: 100%;
  }

  .contacto-buttons {
    justify-content: center;
  }
}
